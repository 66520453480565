import React, { useEffect, useState } from 'react';
import { 
  FileOutlined, 
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import HeaderNavbar from 'components/cmslayout/HeaderNavbar';
import { HiHome, HiInformationCircle, HiOutlineUsers, HiPhotograph } from 'react-icons/hi';
import AddCategoryDrwr from 'cmspages/AddCategoryDrwr';

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(<Link to="/admin-panel">Home Update</Link>, 'home', <HiHome />),
  getItem(<Link to="/about-us-page">About Us Update</Link>, 'About Us', <HiOutlineUsers />),
  getItem(<Link to="/gallery-us">Gallery Update</Link>, 'Gallery', <HiPhotograph />),
  getItem(<Link to="/contact-us">Contact Update</Link>, 'Contact', <HiInformationCircle />),
  getItem('Categories', 'categories', <UserOutlined />, [
    // getItem(<Link to="/about-g">about </Link>, '3'),
    getItem('Add Category', '4'),
    getItem('Alex', '5'),
  ]),
  getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
  getItem('Files', '9', <FileOutlined />),
];


interface HomeLayoutProps {
    children: any;
    className?: string
}

const Cmslayout: React.FC<HomeLayoutProps> = (props) => {

  const [collapsed, setCollapsed] = useState(false);
  const { 
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  const [addCategoriesDrwr, setaddCategoriesDrwr] = useState(false);

  const onMenuClick =  (key:string) => {  
    if (key === '4') {
      setaddCategoriesDrwr(true);
    }
  }
  
  const onClose =  () => { 
    setaddCategoriesDrwr(false);
  }
  
 

  return (
    <Layout style={{ minHeight: '100vh' }} className='adminpanel-layout'>
      <AddCategoryDrwr open={addCategoriesDrwr} onClose={onClose} />
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="demo-logo-vertical" />
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={items} onClick={(e) => onMenuClick(e.key)} />
      </Sider>
      <Layout>
        <HeaderNavbar className='p-0 adminpanelhdr'  />
        <Content> 
          {props.children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>
        © 2020 - {new Date().getFullYear()}. All rights reserved by thedeluxetravels.com 
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Cmslayout;