import React from 'react';
import { Col, Row } from 'react-bootstrap'; 
import TdtIcon from './TdtIcon';

interface Reasonstworkprops{
    reasonsToWorks?:any
    className?: string 
}

const ReasonsToWork: React.FC<Reasonstworkprops> = (props) => {
  
// alert(props.reasonsToWorks.whyyiconlabel)
  return (
    <React.Fragment>
        <Row className={`whysportrow py-3 my-5 ${props.className}`}> 
            <Col sm={12}  className='d-flex gap-3'>
            
               {
                (props.reasonsToWorks.headerContent.mainText &&  props.reasonsToWorks.headerContent.spanText) && <h4 className='whyustextheading'>
                {props.reasonsToWorks.headerContent.mainText}<br/> <span>{props.reasonsToWorks.headerContent.spanText}</span>
              </h4> 
               }
            

            {props.reasonsToWorks.iconData.map((item:any, i:any) => (
              <TdtIcon
                key={i}
                icon={item.icon}
                label={item.label}
                classNamelinkdiv='seticonhome' 
                classNameicon='seticonhomewhy' 
                classNamelbl='setlblhomewhy'
              />
            ))}
           

            </Col> 
        </Row> 
        
    </React.Fragment>
  );
}

export default ReasonsToWork;