import { Form } from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useState } from 'react'; 
import moment from 'moment'; 
import { Col, Row } from 'react-bootstrap'; 
import { Table } from 'antd';
import { TableColumnsType, TableProps, message, Upload } from 'antd'; 
import TdtTable from 'components/websitecomponents/TdtTable'; 
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton';
import { HiUpload } from 'react-icons/hi';
import type { UploadProps } from 'antd';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaRegTrashCan, FaPenToSquare } from 'react-icons/fa6';
import { LiaCommentsDollarSolid } from 'react-icons/lia';

type TableRowSelection<T> = TableProps<T>['rowSelection']; 
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string
} 
interface DataType {
  key: any;
  title: string; 
  dataIndex: string,
}  


const DrwrAddReasons: React.FC<TdtBookDrwerprops> = (props) => {
  const [form] = Form.useForm()
  const formRef = React.useRef<FormInstance>(null);
  const onReset = () => {
      formRef.current?.resetFields();
  };   

    
const tabledata = [
  {
    key: '1',
    name: 'BEST PRICE GUARANTEE', 
  },
  {
    key: '2',
    name: '24X7 LIVE CHAT SUPPORT', 
  },
  {
    key: '3',
    name: 'FAST BOOKING', 
  },
  {
    key: '4',
    name: '5 STAR FACILITIES', 
  },
  {
    key: '5',
    name: 'WIFI COMING SOON', 
  },
];

const tblheadcolumns : TableColumnsType<DataType> = [
  { 
    title: 'Reason',
    dataIndex: 'name', 
  },
  {
    title: 'Action',  
    width:'100px',
    render: (_, record) => (
      <div className='d-flex'>
         <TdtIcon icon={<FaPenToSquare />}  />
         <TdtIcon icon={<FaRegTrashCan />}  />
      </div>
    ),
  } 
];  
 
  const onFinish = (values: any) => {  
    values.start_date = moment(values.start_date).format("YYYY-MM-DD") 
  }
   
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  }; 
    

  const uploadProps: UploadProps = {
    name: 'file',
    action: `${process.env.PUBLIC_URL  + 'assets'}`,
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
        <TdtDrawer title={props.title}  open={props.open} placement={props.placement} onClose={props.onClose} width={'750px'} destroyOnClose={props.destroyOnClose}>
            <Form
                name="add-designation-form"
                form={form}
                ref={formRef}
                // initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
            >
              <Row>
                <Col sm={12} md={6}>
                    <TdtInput label='Banner Heading' />
                </Col> 
                <Col sm={12} md={6}>
                    <TdtButton label={'Save'} htmlType="submit" classNamebtn='me-2 px-5'/>
                </Col>  
              </Row>
            </Form>
            <Row>
                <Col sm={12}>
                    <TdtTable  pagination={false} tblheadcolumns={tblheadcolumns} tabledata={tabledata} />  
                </Col>  
            </Row>
        </TdtDrawer>
  );
}

export default DrwrAddReasons;