import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import TdtImage from "./TdtImage";
import TdtButton from "./TdtButton";
import TdtIcon from "./TdtIcon";
import { HiOutlineChatAlt } from "react-icons/hi";

function TdtTravelPkg() {
   
  return (
    <Container fluid  className='TdtTravelPkg py-5'>
        <Container  className=''>
        
            <Row className="TdtTravelPkg-container   pb-5">
                
                <Col sm={12} className="d-flex justify-content-between pb-3">
                <h5 className="setheadingrow hightlight">Top Rated</h5>
                <h5 className="setheadingrow">Most Popular - Activities</h5>
                <TdtButton label="View All" href="#" />
                </Col>

                <Col className="pb-3" sm={12} md={3} >
                    <a className="planecolumn" href="#">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/airoplanedbd.svg'}/>    
                        <div className="trvlpkg-content" style={{backgroundImage: `url(${process.env.PUBLIC_URL  + 'assets/tra9.png'}   )`}}>
                            
                            <div className="txtcontent-trvlpkg">
                                Theme Park
                            </div>
                            <div className="plncolmoverlays"></div>

                        </div>   
                    </a> 
                </Col>
                <Col className="pb-3" sm={12} md={3} >
                    <a className="planecolumn" href="#">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/airoplanedbd.svg'}/>    
                        <div className="trvlpkg-content" style={{backgroundImage: `url(${process.env.PUBLIC_URL  + 'assets/tra8.png'}   )`}}>
                                                
                            <div className="txtcontent-trvlpkg">
                                Desert Safari
                            </div>
                            <div className="plncolmoverlays"></div>

                        </div>   
                    </a> 
                </Col>
                <Col className="pb-3" sm={12} md={3} >
                    <a className="planecolumn" href="#">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/airoplanedbd.svg'}/>    
                        <div className="trvlpkg-content" style={{backgroundImage: `url(${process.env.PUBLIC_URL  + 'assets/tra2.jpg'}   )`}}>
                            
                            <div className="txtcontent-trvlpkg">
                                Private Tours
                            </div>
                            <div className="plncolmoverlays"></div>

                        </div>   
                    </a> 
                </Col>
                <Col className="pb-3" sm={12} md={3} >
                    <a className="planecolumn" href="#">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/airoplanedbd.svg'}/>    
                        <div className="trvlpkg-content" style={{backgroundImage: `url(${process.env.PUBLIC_URL  + 'assets/tra1.jpg'}   )`}}>
                            
                            <div className="txtcontent-trvlpkg">
                                Sky Tours
                            </div>
                            <div className="plncolmoverlays"></div>

                        </div>   
                    </a> 
                </Col>
                <Col className="pb-3" sm={12} md={3} >
                    <a className="planecolumn" href="#">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/airoplanedbd.svg'}/>    
                        <div className="trvlpkg-content" style={{backgroundImage: `url(${process.env.PUBLIC_URL  + 'assets/tra7.png'}   )`}}>
                            
                            <div className="txtcontent-trvlpkg">
                                Theme Park
                            </div>
                            <div className="plncolmoverlays"></div>

                        </div>   
                    </a> 
                </Col>
                <Col className="pb-3" sm={12} md={3} >
                    <a className="planecolumn" href="#">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/airoplanedbd.svg'}/>    
                        <div className="trvlpkg-content" style={{backgroundImage: `url(${process.env.PUBLIC_URL  + 'assets/tra8.png'}   )`}}>
                            
                            <div className="txtcontent-trvlpkg">
                                Sky Tours
                            </div>
                            <div className="plncolmoverlays"></div>

                        </div>   
                    </a> 
                </Col>
                <Col className="pb-3" sm={12} md={3} >
                    <a className="planecolumn" href="#">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/airoplanedbd.svg'}/>    
                        <div className="trvlpkg-content" style={{backgroundImage: `url(${process.env.PUBLIC_URL  + 'assets/tra9.png'}   )`}}>
                            
                            <div className="txtcontent-trvlpkg">
                                Theme Park
                            </div>
                            <div className="plncolmoverlays"></div>

                        </div>   
                    </a> 
                </Col>
                <Col className="pb-3" sm={12} md={3} >
                    <a className="planecolumn" href="#">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/airoplanedbd.svg'}/>    
                        <div className="trvlpkg-content" style={{backgroundImage: `url(${process.env.PUBLIC_URL  + 'assets/tra7.png'}   )`}}>
                            
                            <div className="txtcontent-trvlpkg">
                                Sea Adventures
                            </div>
                            <div className="plncolmoverlays"></div>

                        </div>   
                    </a> 
                </Col>

            </Row>
        
        </Container>
    </Container>
  );
}

export default TdtTravelPkg;
