import { Button, Form, Input, Space } from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useState } from 'react'; 
import moment from 'moment'; 
import { Col, Row } from 'react-bootstrap'; 
import { Table } from 'antd';
import { TableColumnsType, TableProps, message, Upload } from 'antd'; 
import TdtTable from 'components/websitecomponents/TdtTable'; 
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton';
import { HiUpload } from 'react-icons/hi';
import type { UploadProps } from 'antd';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaPen, FaPenToSquare, FaRegTrashCan } from 'react-icons/fa6';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { WebApi } from 'shared/WebApi';

type TableRowSelection<T> = TableProps<T>['rowSelection']; 
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string
}  
const AddCategoryDrwr: React.FC<TdtBookDrwerprops> = (props) => {
  const [form] = Form.useForm()
  const formRef = React.useRef<FormInstance>(null);
  const onReset = () => {
      formRef.current?.resetFields();
  };   
  
  const onFinish = async (values: any) => {   
    try {
        const response = await WebApi('post', 'api/insert_category' , values);  
        const responseData: any = response; 
        if (responseData.status === 200) { 
             message.success('Data Added Ns')
            }
        else {
          console.error('Error: Request error');  
        }
    } catch (error:any) {
        console.error('Error:', error);  
    }
};
   
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  }; 
    
 
  interface DataType {
    key: string;
    title: string;
    image: any; 
  }
  
  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title', 
    },
    {
      title: 'image',
      dataIndex: 'image',
      key: 'image',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <a>Delete</a> 
      ),
    },
  ];
  
  const data: DataType[] = [
    {
      key: '1',
      title: 'John Brown',
      image: 'ABCD IMAGE', 
    },
    {
      key: '2',
      title: 'Jim Green',
      image: 'ABCD IMAGE', 
    },
    {
      key: '3',
      title: 'Joe Black',
      image: 'cde', 
    },
  ];

  const [uploadfile, setuploadfile] = useState('');
  const handleCompanyLogoChange = (e:any) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const result = event.target?.result;
      if (typeof result === 'string') {
        const img = new Image();
              img.onload = () => { 
              setuploadfile(file);  
              alert('onload Img 1'+ file)
        };
            img.src = result;
            setuploadfile(result);  
            alert('img result 2 2'+result)
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <TdtDrawer title={props.title}  open={props.open} placement={props.placement} onClose={props.onClose} width={'1250px'} destroyOnClose={props.destroyOnClose}>
        <Form
            name="add_categories"
            form={form}
            ref={formRef} 
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            > 
            <Row>
                <Col sm={12} md={3}>
                    <TdtInput label='Category Title' name='title' />
                </Col> 
                <Col sm={12} md={3} className='d-flex flex-column'> 
                    <input type="file" onChange={handleCompanyLogoChange} />
                </Col>  
                <Col sm={12} md={12}>
                    <TdtButton label={'Submit'} htmlType="submit" classNamebtn='me-2 px-5'/> 
                </Col>
            </Row>
        </Form> 
            {/* </Form> */}
            <Row>
                <Col sm={12}>
                    <TdtTable  pagination={false} tblheadcolumns={columns} tabledata={data} />  
                </Col>  
            </Row>
        </TdtDrawer>
  );
}

export default AddCategoryDrwr;