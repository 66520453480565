import React from 'react';   

interface Aboutusprops { 
}

   

export const AboutUsPage: React.FunctionComponent<Aboutusprops> = (props) => {
    

    return ( 

        <React.Fragment>  
             DASHBOARD
        </React.Fragment>
    )
}

export default AboutUsPage;




