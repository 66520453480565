import { Layout, Menu, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import headerlogo from "../../assets/images/deluxetravellogo.svg";
import { Col, Container, Row } from 'react-bootstrap'; 

interface Homeprops {
className?: string
}

const { Header } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
): MenuItem {
    return {
        key,
        icon,
        label,
    } as MenuItem;
} 
const itemsMenuAdmin: MenuItem[] = [
    getItem(<Link to="/desert-safari-dubai-tours"> Desert Safari  </Link>, ' Desert Safari '),
    getItem(<Link to="/sight-seeing-dubai-tours"> Sight Seeing </Link>, ' Sight Seeing  '),
    getItem(<Link to="/private-tours-dubai-tours"> Private Tours </Link>, ' Private Tours '),
    getItem(<Link to="/executive-dubai-tours"> Executive </Link>, ' Executive'),  
    getItem(<Link to="/dhow-cruise-dubai-tours"> Dhow Cruise </Link>, ' Dhow Cruise '),
    getItem(<Link to="/combo-deals-dubai-tours">  Combo Deals  </Link>, ' Combo Deals '), 
]; 
export const HeaderNavbar: React.FunctionComponent<Homeprops> = (props) => {

      
    return (
        <Header className={`setheaderweb ${props.className}`}>
            <Container fluid className='p-0 '>
            <Container>
                    <Row>
                        <Col sm={3}>
                            <img src={headerlogo} alt="" className='w-75'></img>
                        </Col>
                        <Col>
                            {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} items={itemsMenuAdmin} style={{ flex: 1, minWidth: 0 }} className='headertours' /> */}
                        </Col>
                    </Row>  
                </Container >
            </Container >

        </Header>
    )
}

export default HeaderNavbar; 