import { Layout, Menu, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import headerlogo from "../../assets/images/headerlogo.svg";
import { Col, Container, Row } from 'react-bootstrap';
import TdtCart from 'components/websitecomponents/TdtCart'; 
import { BsCartPlus } from "react-icons/bs";
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaEnvelope, FaPhone } from 'react-icons/fa6';  

interface Homeprops {
className?: string
}

const { Header } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
): MenuItem {
    return {
        key,
        icon,
        label,
    } as MenuItem;
}

const itemsMenuAdmin: MenuItem[] = [
    getItem(<Link to="/">Tours</Link>, 'Tours'),
    getItem(<Link to="/about-us">About Us</Link>, 'About Us'),
    getItem(<Link to="/contact-us">Contact us</Link>, 'Contact Us'),
    getItem(<Link to="/gallery">Gallery</Link>, 'Gallery'),
    getItem(<Link to="/about-us" className='cartmenutopbr'><TdtCart icon={<BsCartPlus/>} count={6}/></Link>, 'cart') 
];


export const TopbarHeader: React.FunctionComponent<Homeprops> = (props) => {
   

    return ( 
            <Container fluid className='p-0 hdrtopbar'> 
                <Container >
                    <Row>
                        <Col sm={12} md={6} className='topbrcol1'>
                            <ul className='topbarcontacts d-flex '><li className='d-flex'><TdtIcon classNameicon='pe-1' icon={<FaPhone/>}/> +971 52 953 5800</li><li className='d-flex'><TdtIcon classNameicon='pe-1' icon={<FaEnvelope/>}/> holidays@thedeluxetravel.com </li></ul>
                        </Col>
                        <Col sm={12} md={6} className='topbrcol2'>
                            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} items={itemsMenuAdmin} style={{ flex: 1, minWidth: 0 }} className='topbarmenus' />
                           
                        </Col>
                    </Row>
                </Container >
            </Container > 
    )
}

export default TopbarHeader; 