import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import { motion, useScroll, useSpring } from "framer-motion";
import OurServices from './homesections/OurServices';
import BannerSecTwo from './homesections/BannerSecTwo';
import TdtImage from 'components/websitecomponents/TdtImage';



interface Aboutusprops {

}
export const AboutUs: React.FunctionComponent<Aboutusprops> = (props) => {
   



    return (
        <React.Fragment> 
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="About Us are available for you" />
                <link rel="canonical" href="/about" />
                <meta property="og:title" content="A very important title" />
            </Helmet>   

            <Container fluid className='bannerBread py-5 position-relative' style={{backgroundImage:'url(http://localhost:3000/assets/banner-img-1.png)'}}> 
                <Container className='bannerbredcontent'>
                    <Row>
                        <Col sm={12} className='py-5'>
                        <h4 className='text-light'>About Us</h4>
                        </Col>
                    </Row>
                </Container>
                <div className='ovelaybred'></div>
            </Container> 


            <Container fluid className=''> 
                <Container className='py-5'>
                    <Row> 
                        <Col sm={12}  className='text-center py-5  px-sm-1 px-md-5 '> 
                            <h1 className='py-3'>In 2024 See The World  with The Deluxe Travel</h1>
                            <p className='px-sm-1 px-md-5'>The Deluxe Travel continues their tour in 2024, touching down in Subcontinent,  Europe and  America. Book your travel  needs, from the hotels to <br/> the cheapest flights on Price.The Deluxe Travel continues their tour in 2024, touching  down in Subcontinent,  Europe and  America. <br/>Book your travel  needs, from the hotels to the cheapest flights on Price.</p>
                        </Col>
                    </Row>
                    <Row className='pb-5'> 
                        <Col sm={12} md={4} className='text-center'>
                            <div className='misionvision'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/mision.jpg'} preview={false}/>
                                <h6 className='pt-2'>Our Mission</h6>
                                <p className='p-2'>The Deluxe Travel continues their tour in 2024, touching down in Subcontinent,  Europe and  America. Book your travel  needs,</p>
                            </div>
                         </Col> 
                        <Col sm={12} md={4} className='text-center'>
                            <div className='misionvision'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/mision.jpg'}  preview={false}/>
                                <h6 className='pt-2'>Our Vision</h6>
                                <p className='p-2'>The Deluxe Travel continues their tour in 2024, touching down in Subcontinent,  Europe and  America. Book your travel  needs,</p>
                            </div>
                        </Col>
                        <Col sm={12} md={4} className='text-center'>
                            <div className='misionvision'>
                                <TdtImage src={process.env.PUBLIC_URL  + 'assets/mision.jpg'} preview={false}/>
                                <h6  className='pt-2'>Our Values</h6>
                                <p className='p-2'>The Deluxe Travel continues their tour in 2024, touching down in Subcontinent,  Europe and  America. Book your travel  needs,</p>
                            </div>
                        </Col>
                    </Row>  
                </Container > 
            </Container>
                
            <Container fluid className='whatmakesdifferent'> 
                <Container className='py-5'>
                    
                    <Row className='py-5'> 
                        <Col sm={12} md={6} className=' px-sm-1 px-md-5'>
                            <h3 className='py-3'>What Makes Us Different?</h3>
                            <p>Does not matter who you are, and where you are going, The travel brands help you or every type of traveler not only find the trip which right for them, but get the best for every time. Does not matter who you are, and where you are going, The travel brands help you or every type of traveler not only find the trip which right for them, but get the best for every time.
                            Does not matter who you are, and where you are going, The travel brands help you or every type of traveler not only find the trip which right for them, but get the best for every time.Does not matter who you are, and where you are going, The travel brands help you or every type of traveler not only find the trip which right for them, but get the best for every time.
                            Does not matter who you are, and where you are going, The travel brands help you or every type of traveler not only find the trip which right for them, but get the best for every time.Does not matter who you are, and where you are going, The travel brands help you or every type of traveler not only find the trip which right for them, but get the best for every time.
                            Does not matter who you are, and where you are going, The travel brands help you or every type of traveler not only find the trip which right for them, but get the best for every time.
                            
                            </p>
                        </Col> 
                        <Col sm={12} md={6} className='text-center px-sm-1 px-md-5'>
                            <TdtImage src={process.env.PUBLIC_URL  + 'assets/HP-Hero-Kaley-Spring-en.jpg'}  preview={false}/>
                        </Col> 
                    </Row> 
                </Container> 
            </Container>
            
            <Container fluid className=''> 
                <Container className='py-5'>
                    <Row className=''> 
                        <Col sm={12}  className='text-center py-5  px-sm-1 px-md-5 '> 
                            <h1 className='py-3'>What Makes Us Different?</h1>
                            <p className='px-sm-1 px-md-5'>In a world full of travel choices, finding the right partner for your desert safari tour is like turning any vacation into a journey. What sets us apart at Arabian Tour Packages is our unique approach to exploring the United Arab Emirates.

Our ethos is not just to see new places, but to experience them in a way that does them justice and may be perfectly respectful, enriching, and very deeply personal to you.

Herein, we dive down into the core that makes us different.</p>
                        </Col>
                        <Col sm={12} md={4} className='text-center  px-sm-1 px-md-5'>
                            <TdtImage src={process.env.PUBLIC_URL  + 'assets/aboutsUs.png'} preview={false}/>
                            <h5 className='py-3'>Travel With Us</h5>
                            <p>Does not matter who you are, and where you are going, The travel brands help you or every type of traveler not only find the trip which right for them, but get the best for every time.</p>
                        </Col> 
                        <Col sm={12} md={4} className='text-center px-sm-1 px-md-5'>
                            <TdtImage src={process.env.PUBLIC_URL  + 'assets/aboutsUs1.png'}  preview={false}/>
                            <h5 className='py-3'>Plan Trips With Us</h5>
                            <p>Connect partners big  to the universe of travelers, give access to data, tools and IT that empowers, Increase potential and builds their business.</p>
                        </Col>
                        <Col sm={12} md={4} className='text-center px-sm-1 px-md-5'>
                            <TdtImage src={process.env.PUBLIC_URL  + 'assets/aboutsUs1.png'}  preview={false}/>
                            <h5 className='py-3'>Plan Trips With Us</h5>
                            <p>Connect partners big  to the universe of travelers, give access to data, tools and IT that empowers, Increase potential and builds their business.</p>
                        </Col>
                    </Row> 
                </Container> 
            </Container>



        </React.Fragment> 


    )
}

export default AboutUs;




