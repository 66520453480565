import React, { useEffect } from 'react';
import { Button, Checkbox, Form, type FormProps, Input} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {WebApi}  from '../shared/WebApi'
import { Col, Container, Row } from 'react-bootstrap';
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaEnvelope, FaUser } from 'react-icons/fa6';
import TdtButton from 'components/websitecomponents/TdtButton';
 
type FieldType = {
  name?: string;
  password?: string;
  remember?: string;
};
 
// const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
//   console.log('Success:', values);
// };
 
// const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
//   console.log('Failed:', errorInfo);
// };
 

interface LoginProps {
    children?: any,
}
 
const PasswordForget: React.FC<LoginProps> = (props) => {
 
    
  const [form] = Form.useForm()
  const initialValues = { location_type : "Head Office"}
  const onFinishFailed = (errorInfo: any) => {
      console.log('Failed:', errorInfo);
  };

    const navigate = useNavigate();
  
    const onFinish = async (values: any) => {
       
        values.token = "";
        localStorage.setItem('token', values.token)
    
 
        try {
            const response = await WebApi('post', 'api/login' , values, values.token);
            
            const responseData: any = response;
            debugger 
            if (responseData.status === 200) {
              debugger
                const modifiedData = responseData.data;
 
                if (modifiedData.status === 200) {
                  debugger
                  localStorage.setItem('token', modifiedData.token) 
                  console.log("Response Data:"+ modifiedData)
                  navigate('/dashboard')
                }
                else
                {
                  console.error('Error:', modifiedData.message);  
                }
            }
            else
            {
              console.error('Error: Request error');  
            }
        } catch (error:any) {
            console.error('Error:', error);  
        }
    };
 
 
// const  getGender  = async (values: any) => {
   
   
//   values.token = "";
//   localStorage.setItem('token', values.token)
 
//   try {
//       const response = await WebApi('get', 'api/getfemales' , values, values.token);
//       const responseData: any = response;
//       // debugger
//       if (responseData.status === 200) {
//           const modifiedData = responseData.data;  
//           console.log("Response Data:"+ modifiedData)
//           // debugger
//           history.push('/dashboard')
//       }
//   } catch (error:any) {
//       console.error('Error:', error);  
//   }
 
// }
 
  return (
    <Container fluid className='loginpage'> 
      <Container >
        <Row className='justify-content-center'>
            <Col md={6} className='colonelogin colloginp'>
              <h2> Forget Password </h2>
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum beatae reiciendis pariatur, deleniti soluta perspiciatis quo ducimus id aspernatur maiores omnis recusandae aperiam totam? Voluptates accusamus a consequatur aliquid tenetur.</p>
            </Col>
            <Col md={6} className='coltwologin colloginp'>
            <Form
              name="LoginForm"
              form={form} 
              initialValues={initialValues}
              // initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical" 
            >
               <Row>
               <Col sm={12}>
                  <TdtInput
                      className="inputclas class-subject"
                      name="subject"
                      type="text"
                      required={true}
                      requiredMessage="Please input your Email!"
                      label='Enter Email'
                      placeholder='Enter Recovery Email'
                      allowClear={true}
                      prefix={<TdtIcon icon={<FaEnvelope/>}/>}
                      size="large"
                      ruleType='text'
                  />  
                </Col>
                <Col sm={12} md={12}>
                  <TdtButton label={'Submit'} htmlType="submit" classNamebtn='me-2'/>
                  <a href='/deluxe-login'>Login</a>
                </Col>
              </Row> 
              </Form> 
            </Col>
        </Row>
      </Container>
    </Container> 
  );
}
 
export default PasswordForget;