import TdtButton from 'components/websitecomponents/TdtButton';
import TdtIcon from 'components/websitecomponents/TdtIcon'; 
import React, { useState } from 'react';

import { Avatar, Collapse, List, Slider } from "antd"; 
import { Col, Container, Row } from 'react-bootstrap';  
  
import type { CollapseProps, TableColumnsType, TableProps } from 'antd'; 
import TdtReactSlider from 'components/websitecomponents/TdtReactSlider';
import { FaCheck, FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaPhone, FaPhoneVolume, FaRegStarHalfStroke, FaSquarePhone, FaStar, FaWhatsapp, FaYoutube } from 'react-icons/fa6';
import TdtTable from 'components/websitecomponents/TdtTable';
import { HiOutlineChatAlt } from 'react-icons/hi';
import ReasonsToWork from 'components/websitecomponents/ReasonsToWork';
import TdtListStyle from 'components/websitecomponents/TdtListStyle';
import TdtTimeline from 'components/websitecomponents/TdtTimeline';
import TdtCauroselPackage from 'components/websitecomponents/TdtCauroselPackage';
import TdtImage from 'components/websitecomponents/TdtImage';
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import TdtBookDrwer from './TdtBookDrwer';
 



 
interface DesertSafariprops {

}
interface DataType {
  key: React.Key;
  tourserviceone: any;
  tourservicetwo: any; 
} 


export const TourDetail: React.FunctionComponent<DesertSafariprops> = (props) => {
   

  const text = `A dog is a type of domesticated animal. Known for its loyalty and faithfulness,  it can be found as a welcome guest in many households across the world.`;


  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Enchanting Overview of Desert Safari Dubai',
      children: <p>{text}</p>,
    },
    {
      key: '2',
      label: 'Types of Desert Safari | Sunrise Desert Safari | Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } ,
    {
      key: '3',
      label: 'Sunrise Desert Safari | Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } ,
    {
      key: '4',
      label: 'Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } 
  ];

  const faqstems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Enchanting Overview of Desert Safari Dubai',
      children: <p>{text}</p>,
    },
    {
      key: '2',
      label: 'Types of Desert Safari | Sunrise Desert Safari | Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } ,
    {
      key: '3',
      label: 'Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } ,
    {
      key: '4',
      label: 'Sunrise Desert Safari | Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } ,
    {
      key: '5',
      label: 'Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } 
  ];

  
  const reasonsToWorks = {
    "headerContent": {
      "mainText": "Reasons to",
      "spanText": "Book With Us"
    },
    "iconData": [
      {
        "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
        "label": "BEST PRICE GUARANTEE", 
      },
      {
        "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
        "label": "24X7 LIVE CHAT SUPPORT", 
      },
      {
        "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
        "label": "FAST BOOKING", 
      },
      {
        "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
        "label": "5 STAR FACILITIES", 
      },
      {
        "icon": <TdtIcon icon={<HiOutlineChatAlt />}/>,
        "label": "WIFI COMING SOON", 
      },
     
    ]
  }
  const tblheadcolumns: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'tourserviceone',
    },
    {
      title: 'Chinese Score',
      dataIndex: 'tourservicetwo',
      // sorter: {
      //   compare: (a, b) => a.chinese - b.chinese,
      //   multiple: 3,
      // },
    },  
  ];


  
  
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  

  // const tabledata: DataType[] = [
  //   {
  //     key: '1',
      
      
  //   },
  //   {
  //     key: '2',
      
       
  //   },
  //   {
  //     key: '3',
  //   },
  //   {
  //     key: '4',
  //   },
  // ];
  const tabledata = {
    "buttonbook": {
      "bookbutton":<div className='d-flex justify-content-between'><div className='strtfmrdtl'><b>Start From</b><span className='dtlstrtfrm ps-1'>750 / Vehicle</span></div>
     
      <TdtButton label={'Book Now'} onClick={showDrawer}/></div>, 
    },
    "iconData": [
      {
        tourserviceone: <div className='d-flex servsdetailpg servsdetailpg1'><TdtIcon icon={<FaStar />}/>Tour Service:<span>Daily</span></div>,
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg11'><TdtIcon icon={<FaStar />}/>Duration:<span>3 Hours</span></div>, 
      },
      {
        tourserviceone: <div className='d-flex servsdetailpg servsdetailpg2'><TdtIcon icon={<FaStar />}/>Pick up & Drop Back</div>,
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg22'><TdtIcon icon={<FaStar />}/>Pick Up: 07:30 am to 08:00 am</div>, 
      },
      {
        tourserviceone: <div className='d-flex servsdetailpg servsdetailpg3'><TdtIcon icon={<FaStar />}/>Lang: English / Arabic</div>,
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg33'><TdtIcon icon={<FaStar />}/>Free Wifi Available</div>, 
      },
      {
        tourserviceone: <div className='d-flex servsdetailpg servsdetailpg4'><TdtIcon icon={<FaStar />}/>Adult: AED <del>200</del> 150</div>,
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg44'><TdtIcon icon={<FaStar />}/>Child: AED <del>101.25</del> 101.25</div>,  
      }
    ]
  }



  const tabledatas = {
    "buttonbook": {
      "startfrom ": '750',
    },
    "iconData": [
      {
        tourserviceone: 'Tour Service: Daily',
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg11'><TdtIcon icon={<FaStar />}/>Duration:<span>3 Hours</span></div>, 
      },
      {
        tourserviceone: <div className='d-flex servsdetailpg servsdetailpg2'><TdtIcon icon={<FaStar />}/>Pick up & Drop Back</div>,
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg22'><TdtIcon icon={<FaStar />}/>Pick Up: 07:30 am to 08:00 am</div>, 
      },
      {
        tourserviceone: <div className='d-flex servsdetailpg servsdetailpg3'><TdtIcon icon={<FaStar />}/>Lang: English / Arabic</div>,
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg33'><TdtIcon icon={<FaStar />}/>Free Wifi Available</div>, 
      },
      {
        tourserviceone: <div className='d-flex servsdetailpg servsdetailpg4'><TdtIcon icon={<FaStar />}/>Adult: AED <del>200</del> 150</div>,
        tourservicetwo: <div className='d-flex servsdetailpg servsdetailpg44'><TdtIcon icon={<FaStar />}/>Child: AED <del>101.25</del> 101.25</div>,  
      }
    ]
  }


  const listdata =[
    <TdtIcon icon={<FaCheck />} label={<React.Fragment> <b> Our Support team is highly knowledgeable and never transfers you to another department.</b></React.Fragment>}/>,
    <TdtIcon icon={<FaCheck />} label={<React.Fragment> <b> Our Support team is highly knowledgeable and never transfers you to another department.</b></React.Fragment>}/>,
    <TdtIcon icon={<FaCheck />} label={<React.Fragment> <b> Our Support team is highly knowledgeable and never transfers you to another department.</b></React.Fragment>}/>,
    <TdtIcon icon={<FaCheck />} label={<React.Fragment> <b> Our Support team is highly knowledgeable and never transfers you to another department.</b></React.Fragment>}/>,
    <TdtIcon icon={<FaCheck />} label={<React.Fragment> <b> Our Support team is highly knowledgeable and never transfers you to another department.</b></React.Fragment>}/>,
    <TdtIcon icon={<FaCheck />} label={<React.Fragment> <b> Our Support team is highly knowledgeable and never transfers you to another department.</b></React.Fragment>}/>,
  ]

  const listreviewdata=[
    <List.Item.Meta
    avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
    title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Jhone Doe </span><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
    description="Absolutely loved the Evening Desert Safari.Watching the sunset over the dunes was breathtaking, and the traditional BBQ dinner under the stars was delightful. A well-organized and memorable experience."
  />,
  <List.Item.Meta
  avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
  title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Jhone Doe </span> <TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
  description="Watching the sunset over the dunes was breathtaking, and the traditional BBQ dinner under the stars was delightful. A well-organized and memorable experience."
/>, 
    <List.Item.Meta
    avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
    title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Jhone Doe </span><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
    description="Evening Desert Safari.Watching the sunset over the dunes was breathtaking, and the traditional BBQ dinner under the stars was delightful. A well-organized and memorable experience."
  />, 
  <List.Item.Meta
  avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
  title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Jhone Doe </span><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
  description="Absolutely loved the Evening Desert Safari.Watching the sunset over the dunes was breathtaking, and the traditional BBQ dinner under the stars was delightful. A well-organized and memorable experience."
/>, 
    <List.Item.Meta
    avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
    title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Jhone Doe </span> <TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
    description="Dunes was breathtaking, and the traditional BBQ dinner under the stars was delightful. A well-organized and memorable experience."
  />, 
  <List.Item.Meta
  avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=1`} />}
  title={ <div className='d-flex pb-2 gap-1'> <span className='revsname'> Jhone Doe </span><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/></div>}
  description="Absolutely loved the Evening Desert Safari.Watching the sunset over the dunes was breathtaking, and the traditional BBQ dinner under the stars was delightful. A well-organized and memorable experience."
/>,   
  ]
  

  const itemstimeline = [{
    children: '1 seat Quad Bike for 30 minutes',
    color: 'orange',
  },
  {
    children: '1 seat Quad Bike for 1 hour',
    color: 'orange',
  },
  {
    children: '2 seat Quad Bike for 30 minutes',
    color: 'orange',
  },
  {
    children: '2 seat Quad Bike for 1 hour',
    color: 'orange',
  },
  {
    children: '2 seat Dune Buggy for 30 minutes',
    color: 'orange',
  },
  {
    children: '2 seat Dune Buggy for 1 Hour',
    color: 'orange',
  },
  {
    children: '4 seat Dune Buggy 30 minutes',
    color: 'orange',
  },
  {
    children: '4 seat Dune Buggy for 1 Hour',
    color: 'orange',
  },];
  
    const onChangeSort: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
      console.log('params', pagination, filters, sorter, extra);
    };

    
const relatedpackages = [
  {
      img: process.env.PUBLIC_URL  + 'assets/tra7.png',
      title: "Places to visit in Dubai 4", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra8.png',
      title: "Places to visit in Dubai 5", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra9.png',
      title: "Places to visit in Dubai 6", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra10.png',
      title: "Places to visit in Dubai 7", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra11.png',
      title: "Places to visit in Dubai 8", 
  },
  {
      img: process.env.PUBLIC_URL  + 'assets/tra12.png',
      title: "Places to visit in Dubai 9", 
  },
]; 

    return ( 
        <React.Fragment> 

          <TdtBookDrwer destroyOnClose={true} title={'Booking Detail'} open={open} onClose={onClose}/>

          <Container fluid className='bannerBread py-5 position-relative' style={{backgroundImage:'url(http://localhost:3000/assets/banner-img-1.png)'}}> 
            <Container className='bannerbredcontent'>
              <Row>
                <Col sm={12} className='py-5'>
                  <h4 className='text-light'>Desert Safari Dubai Tours</h4>
                </Col>
              </Row>
            </Container>
            <div className='ovelaybred'></div>
          </Container> 

          <Container fluid className='position-relative py-5'> 
            <Container>
              <Row>
                <Col sm={12} md={7} className='listcrdcontent gap-5'> 
                    <TdtReactSlider customPaging={process.env.PUBLIC_URL  + 'assets/airoplanedbd.svg'}  dots={false}/>
                    <div className='pt-1'>
                      <h1 className='detailpakglbl'>Safari with Bab AL Shams Dinner</h1>
                      <div className='detailpakgrating d-flex pb-2'><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaStar />}/><TdtIcon icon={<FaRegStarHalfStroke />}/> <span className='revstxt'>Reviews</span><span className='px-2'>|</span><span className='revstxttags'> uae </span>, <span className='revstxttags'> dubai </span>, <span className='revstxttags'> Safari with Bab AL Shams Dinner</span></div>
                    </div>
                    <div>
                      <h5 className='pb-2 pt-2'>Itinerary:</h5><p>Situated in the heart of the desert and nestled in the dunes of Dubai, Bab Al Shams Desert Resort & Spa is the ultimate luxury retreat.

                      An exclusive low-rise resort in the desert surrounded by gently sloping dunes and natural desert landscape, Bab Al Shams offers guests a one of its kind traditional desert experience that is true to the culture and heritage of Dubai and the UAE.

                      With its literal translation from Arabic meaning "gateway to the sun", Bab Al Shams is built in an Arabic fort setting in and around an oasis. Inside the walls, shaded courtyards, walkways and water features provide cool shelter and maintain the unique ambience and atmosphere.

                      Bab Al Shams Desert Resort & Spa is located in the heart of the desert, adjacent to Dubai International Endurance City.</p>
                    </div>
                    {/* <div className='pt-4'>
                      <h5>Package Inclusions:</h5> 
                      <TdtListStyle listdata={listdata} bordered={true} className='pkgdtlincude' />
                    </div>  */}
                    <div className='pt-4'>
                      <h5 className='pb-3'>Package Inclusions:</h5> 
                      <TdtTimeline timelineitems={itemstimeline} />
                    </div>
                    <div className=''>
                      <h5 className=''>Reviews</h5>  
                      <div className="d-flex justify-content-between pt-3 pb-2">
                          <h5 className="setheadingrow hightlight">Google Reviews</h5>
                          <TdtButton label="Leave a review" href="#" />
                      </div>  
                      <TdtListStyle listdata={listreviewdata} bordered={true} className='pkgdtlrevies' />
                    </div>
                    <div className='pt-3'>
                      <h5 className=''>Our Best Selling Tours</h5> 
                      <TdtCauroselPackage adventureindubai={relatedpackages} dots={false} infinite={true} speed={500} slidesToShow={3} slidesToScroll={1} className=' mt-m15'/>  
                    </div>  
                    
                </Col>
                <Col sm={12} md={5} className='drtsfrsidbar'>
                  <div className='sidebartrours'>
                    <TdtTable showHeader={false} showFooter={tabledata.buttonbook.bookbutton} bordered={true} tblheadcolumns={tblheadcolumns} tabledata={tabledata.iconData} pagination={false} onChangeSort={onChangeSort}/>
                    <ReasonsToWork  reasonsToWorks={reasonsToWorks} className='detaipageresons' /> 
                    <div className='contactsinglecard'>
                      <div className='headercontact'>
                        <TdtImage src={process.env.PUBLIC_URL  + 'assets/deluxetravellogo.svg'} classNameimg='w-75' preview={false} />
                      </div>
                      <div className='contentcontact'>
                        <h6 className='pb-3'>Need Help?</h6> 
                        <ul className='liststyle '><li><a href='https://web.whatsapp.com/send?phone=+971 52 953 5800'><TdtIcon icon={<FaSquarePhone/>} label={'+971 52 953 5800'}/></a></li><li> <a href='https://web.whatsapp.com/send?phone=+971 52 953 5800'><TdtIcon icon={<FaWhatsapp/>} label={'+971 52 953 5800'}/></a> </li><li> <a href='mailto:holidays@thedeluxetravel.com'><TdtIcon icon={<FaEnvelope/>} label={'holidays@thedeluxetravel.com'}/></a> </li></ul>
                        <p className='pt-3'>Available 24/7</p> 
                      </div>  
                      <div className='contactsnglfootr'>
                        <ul className=' d-flex liststyle '><li><TdtIcon icon={<FaLinkedin/>}/></li><li> <TdtIcon icon={<FaFacebook/>}/></li><li> <TdtIcon icon={<FaInstagram/>}/></li><li><TdtIcon icon={<FaYoutube/>}/></li></ul>
                      </div>
                    </div> 
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </React.Fragment>
    )
}

export default TourDetail;





