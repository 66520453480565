import React, { useEffect } from 'react';
import { Button, Checkbox, Form, type FormProps, Input} from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import {WebApi}  from '../shared/WebApi'
import { Col, Row } from 'react-bootstrap';
 
type FieldType = {
  name?: string;
  password?: string;
  remember?: string;
};
 
const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
  console.log('Success:', values);
};
 
const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log('Failed:', errorInfo);
};
 
interface LoginProps {
    // children: any,
}
 
const ResetPassword: React.FC<LoginProps> = (props) => {
 
 
    const navigate = useNavigate();
 
    const GetUsers = async (values: any) => {
       
        values.token = "";
        localStorage.setItem('token', values.token)
    
 
        try {
            const response = await WebApi('post', 'api/forget-password' , values, values.token);
            
            const responseData: any = response;
            debugger 
            if (responseData.status === 200) {
              debugger
                const modifiedData = responseData.data;
 
                if (modifiedData.status === 200) {
                  debugger
                  localStorage.setItem('token', modifiedData.token) 
                  console.log("Response Data:"+ modifiedData)
                  navigate('/dashboard')
                }
                else
                {
                  console.error('Error:', modifiedData.message);  
                }
            }
            else
            {
              console.error('Error: Request error');  
            }
        } catch (error:any) {
            console.error('Error:', error);  
        }
    };
 
 
// const  getGender  = async (values: any) => {
   
   
//   values.token = "";
//   localStorage.setItem('token', values.token)
 
//   try {
//       const response = await WebApi('get', 'api/getfemales' , values, values.token);
//       const responseData: any = response;
//       // debugger
//       if (responseData.status === 200) {
//           const modifiedData = responseData.data;  
//           console.log("Response Data:"+ modifiedData)
//           // debugger
//           history.push('/dashboard')
//       }
//   } catch (error:any) {
//       console.error('Error:', error);  
//   }
 
// }
 
  return (
    <>
    <Row className='justify-content-center mx-auto py-5'>
        <Col md={4}>
          <Form
              name="basic"  
              initialValues={{ remember: true }}
              onFinish={GetUsers}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
              
 
              <Form.Item
              label="Email: test@example.com"
              name="email"
              rules={[{ required: true, message: 'Please input your name!' }]}
              >
              <Input />
              </Form.Item>
 
              
 
              <Form.Item
              label="Password: password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
              >
              <Input.Password />
              </Form.Item>

              <Form.Item
              label="ConfirmPassword: password"
              name="confirmpassword"
              rules={[{ required: true, message: 'Please input your password!' }]}
              >
              <Input.Password />
              </Form.Item>
 

 
              <Form.Item>
              <Button type="primary" htmlType="submit">
                  Submit
              </Button>
              </Form.Item>
          </Form> 
        </Col>
        <Col md={12}>
            <a href='/login'>Login</a>
        </Col>
    </Row>
           
    </>
  );
}
 
export default ResetPassword;