import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import TdtImage from "./TdtImage";
import TdtButton from "./TdtButton"; 


interface Tdtreactsliderprops {
  customPaging?: any,
  label?: string
  dots?:boolean
}

export const TdtReactSlider: React.FunctionComponent<Tdtreactsliderprops> = (props) => {
  const settings = {
    dots: props.dots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // ...(props.customPaging && {
    //     customPaging: function (i: any) {
    //       return (
    //         <a>
    //           <img src={props.customPaging} />
    //         </a>
    //       );
    //     },
    //   }),
  };
  return ( 
      <Row className="slider-container">  
        <Col className="p-0">
          <Slider {...settings}> 
            <div className="card-setsider pb-2">
              <TdtImage preview={false} src={'https://www.rustyparrot.com/wp-content/uploads/2022/05/person-in-red-jacket-standing-on-green-grass-field-near-snow-4390725-1.jpg'}/>
              {props.label && <h6 className="settitlepkg">Places to visit in Duba 6</h6>}
            </div>
            <div className="card-setsider pb-2">
              <TdtImage preview={false} src={'https://www.rustyparrot.com/wp-content/uploads/2022/05/person-in-red-jacket-standing-on-green-grass-field-near-snow-4390725-1.jpg'}/>
              {props.label && <h6 className="settitlepkg">Places to visit in Duba 5</h6>}
            </div> 
            <div className="card-setsider pb-2">
              <TdtImage preview={false} src={'https://www.rustyparrot.com/wp-content/uploads/2022/05/person-in-red-jacket-standing-on-green-grass-field-near-snow-4390725-1.jpg'}/>
              {props.label && <h6 className="settitlepkg">Places to visit in Duba 7</h6>}
            </div> 
            <div className="card-setsider pb-2">
              <TdtImage preview={false} src={'https://www.rustyparrot.com/wp-content/uploads/2022/05/person-in-red-jacket-standing-on-green-grass-field-near-snow-4390725-1.jpg'}/>
              {props.label && <h6 className="settitlepkg">Places to visit in Duba 8</h6>}
            </div> 
            <div className="card-setsider pb-2">
              <TdtImage preview={false} src={'https://www.rustyparrot.com/wp-content/uploads/2022/05/person-in-red-jacket-standing-on-green-grass-field-near-snow-4390725-1.jpg'}/>
              {props.label && <h6 className="settitlepkg">Places to visit in Duba 9</h6>}
            </div> 
          </Slider>
        </Col> 
      </Row> 
  );
}

export default TdtReactSlider;
