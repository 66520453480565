import { Avatar, Badge, Collapse, CollapseProps } from 'antd';
import React from 'react'; 
import { Col, Container, Row } from 'react-bootstrap';
import TdtIcon from './TdtIcon';
import { FaCheck } from "react-icons/fa6";
import TdtImage from './TdtImage';
import TdtListStyle from './TdtListStyle';

interface Reasonstworkprops{
    icon?:any 
    count?:number
}

const TdtFaqs: React.FC<Reasonstworkprops> = (props) => {
  
    const text = `A dog is a type of domesticated animal. Known for its loyalty and faithfulness,  it can be found as a welcome guest in many households across the world.`;
    
    const faqstems: CollapseProps['items'] = [
      {
        key: '1',
        label: 'Enchanting Overview of Desert Safari Dubai',
        children: <p>{text}</p>,
      },
      {
        key: '2',
        label: 'Types of Desert Safari | Sunrise Desert Safari | Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
        children: <p>{text}</p>,
      } ,
      {
        key: '3',
        label: 'Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
        children: <p>{text}</p>,
      } ,
      {
        key: '4',
        label: 'Sunrise Desert Safari | Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
        children: <p>{text}</p>,
      } ,
      {
        key: '5',
        label: 'Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
        children: <p>{text}</p>,
      } 
    ];
 

  return (
    <Container fluid className='Tdtfaqs pb-5'>
        <Container className='pb-5'>
            <Row> 
                <Col sm={12}  className='text-center py-5  px-sm-1 px-md-5 '> 
                    <h1 className='pb-3'>Frequently Ask Questions</h1>
                    <p className='px-sm-1 px-md-5'>The Deluxe Travel continues their tour in 2024, touching down in Subcontinent,  Europe and  America. Book your travel  needs, from the hotels to <br/> the cheapest flights on Price.The Deluxe Travel continues their tour in 2024, touching  down in Subcontinent,  Europe and  America. <br/>Book your travel  needs, from the hotels to the cheapest flights on Price.</p>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={6} className='faqs-col1'>
                    <Collapse accordion items={faqstems} defaultActiveKey={['1']} />
                </Col>
                <Col className='faqs-col2'>
                    <Collapse accordion items={faqstems} defaultActiveKey={['1']} />  
                </Col>
            </Row>
        </Container>
    </Container>
  );
}

export default TdtFaqs;