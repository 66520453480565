import { Form } from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useState } from 'react'; 
import moment from 'moment'; 
import { Col, Row } from 'react-bootstrap'; 
import { Table } from 'antd';
import { TableColumnsType, TableProps, message, Upload } from 'antd'; 
import TdtTable from 'components/websitecomponents/TdtTable'; 
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton';
import { HiUpload } from 'react-icons/hi';
import type { UploadProps } from 'antd';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaPen, FaPenToSquare, FaRegTrashCan } from 'react-icons/fa6';
import { LiaCommentsDollarSolid } from 'react-icons/lia';

type TableRowSelection<T> = TableProps<T>['rowSelection']; 
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string
} 
interface DataType {
  key: any;
  banner_heading: string;
  banner_description: string;
  banner_image: string; 
}  
const tabledata: DataType[] = [];
for (let i = 0; i < 5; i++) {
  tabledata.push({
    key: i,
    banner_heading: `Tour Option `,
    banner_description: `AED 100 for ${i} Person(s)`,
    banner_image: `qty_adults. `, 
  });
}  
const DrwrAddBanner: React.FC<TdtBookDrwerprops> = (props) => {
  const [form] = Form.useForm()
  const formRef = React.useRef<FormInstance>(null);
  const onReset = () => {
      formRef.current?.resetFields();
  };   
const tblheadcolumns: TableColumnsType<DataType> = [
    {
      title: 'Heading',
      dataIndex: 'banner_heading',
      width:'280px'
    },
    {
      title: 'Description',
      dataIndex: 'banner_description',
      width:'380px'
    },
    {
      title: 'Image',
      dataIndex: 'banner_image',   
    }, 
    {
      title: 'Action',
      width:'100px',
      dataIndex: 'banner_image',   
      render: (_, record) => (
        <div className='d-flex'>
           <TdtIcon icon={<FaPenToSquare />}  />
           <TdtIcon icon={<FaRegTrashCan />}  />
        </div>
      ),
    } 
  ];
 
  const onFinish = (values: any) => {  
    values.start_date = moment(values.start_date).format("YYYY-MM-DD") 
  }
   
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  }; 
    

  const uploadProps: UploadProps = {
    name: 'file',
    action: `${process.env.PUBLIC_URL  + 'assets'}`,
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
        <TdtDrawer title={props.title}  open={props.open} placement={props.placement} onClose={props.onClose} width={'1250px'} destroyOnClose={props.destroyOnClose}>
            <Form
                name="add-designation-form"
                form={form}
                ref={formRef}
                // initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
            >
              <Row>
                <Col sm={12} md={3}>
                    <TdtInput label='Banner Heading' />
                </Col>
                <Col sm={12} md={3}>
                    <TdtInput label='Banner Description' />
                </Col> 
                <Col sm={12} md={3} className='d-flex flex-column'> 
                    <Upload {...uploadProps}>
                        <TdtButton icon={<HiUpload />}></TdtButton>
                    </Upload>
                </Col> 
                <Col sm={12} md={3}>
                    <TdtButton label={'Save'} htmlType="submit" classNamebtn='me-2 px-5'/>
                </Col> 
              </Row>
            </Form>
            <Row>
                <Col sm={12}>
                    <TdtTable  pagination={false} tblheadcolumns={tblheadcolumns} tabledata={tabledata} />  
                </Col>  
            </Row>
        </TdtDrawer>
  );
}

export default DrwrAddBanner;