import { Divider, Layout, Menu, MenuProps } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import footerlogo from "../../assets/images/footerlogo.svg";
import { Col, Container, Row } from 'react-bootstrap';
import TdtListStyle from 'components/websitecomponents/TdtListStyle';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { BsFacebook, BsGoogle, BsInfoCircle, BsInstagram, BsLinkedin, BsTiktok, BsTwitter, BsYoutube } from 'react-icons/bs';
import TdtImage from 'components/websitecomponents/TdtImage';

interface Footerareaprops {

}

const { Footer } = Layout;
 

export const FooterArea: React.FunctionComponent<Footerareaprops> = (props) => {


    const listdata = [
        <a href='#'> 1 list item one</a>,
        <a href='#'> 2 list item two</a>,
        <a href='#'> 3 list item three</a>,
        <a href='#'> 4 list item four</a>,
        <a href='#'> 5 list item five</a>,
        <a href='#'> 5 list item five</a>,
        <a href='#'> 5 list item five</a>,
        <a href='#'> 5 list item five</a>,
        <a href='#'> 5 list item five</a>,
    ]; 


    return (

        <Footer style={{backgroundImage:'url(http://localhost:3000/assets/footerbg.png)'}} className='footmain position-relative'>
         <div className='footr-content'> 
            <Container fluid className='footertopmain pt-4'>
                <Container >
                    <Row>
                        <Col>
                            <div className='col1menu1'>
                                <h6 className='text-light pb-2'>Quick Links</h6>
                                <TdtListStyle listdata={listdata} className='stylelist' bordered={false}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='col2menu2'>
                            <h6 className='text-light pb-2'>UAE Activities</h6>
                                <TdtListStyle listdata={listdata} className='stylelist' bordered={false}/>
                            </div>
                        </Col>
                       
                        <Col className='footrmenu3'> 
                            <Divider className='divider-footer' type='vertical'> </Divider>

                            <div className='col3menu1'>
                            <h6 className='text-light pb-2'>Desert Safari Tours</h6>
                                <TdtListStyle listdata={listdata} className='stylelist' bordered={false}/>
                            </div>
                        </Col>
                        <Col> 
                            <div className='col4menu2'>
                            <h6 className='text-light pb-2'>Sightseeing Tours</h6>
                                <TdtListStyle listdata={listdata} className='stylelist' bordered={false}/>
                            </div>
                        </Col>
                        <Col> 
                            <div className='col5menu2'>
                            <h6 className='text-light pb-2'>Best Selling Tours</h6>
                                <TdtListStyle listdata={listdata} className='stylelist' bordered={false}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className='footermid text-center py-3'>
                <Container >
                    <Divider className='divider-footer'>
                        <TdtIcon icon={<BsFacebook/>} />
                        <TdtIcon icon={<BsInstagram/>} />
                        <TdtIcon icon={<BsYoutube/>} />
                        <TdtIcon icon={<BsLinkedin/>} />
                        <TdtIcon icon={<BsTwitter/>} />
                        <TdtIcon icon={<BsGoogle/>} />
                        <TdtIcon icon={<BsTiktok/>} />
                    </Divider>
                </Container>
                <Container className='copyrightcontrn mt-4'>
                    <Link to="/"><TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/footrlights.svg'} classNameimg='w-sm-100'/> </Link>
                    <div className='text-light mt-4'>© 2020 - {new Date().getFullYear()}. All rights reserved by <Link to="/">thedeluxetravels.com</Link></div>
                </Container>
            </Container>
 
         </div> 
        <div className='ftroverlay'></div>
        </Footer>


    )
}

export default FooterArea; 