import Carousel from 'react-bootstrap/Carousel';

interface Carouselprops{
    bannerdescription?:any
}

const TdtBannerCauroselTravel: React.FC<Carouselprops> = (props) => {
  return (
    <Carousel>
        {props.bannerdescription.map((item:any, i:any) => (
            <Carousel.Item key={i}>
                <img
                className="d-block w-100"
                src={item.img}
                alt={item.title}
                />
                <Carousel.Caption className='banertxtcontn'>
                <h1 className='pb-3 bnrhead'>{item.title}</h1>
                <p className='bnrdescr'>{item.description}</p>
                </Carousel.Caption>
            </Carousel.Item>
        ))} 
    </Carousel>
  );
}

export default TdtBannerCauroselTravel;