import React from 'react';
import { Timeline } from 'antd';


interface Tdttimelineprops {
    className?: string,
    timelineitems?: any 
}
  
const TdtTimeline: React.FC<Tdttimelineprops> = (props) => {
    
  return ( 
        <Timeline items={props.timelineitems} />
    );
}

export default TdtTimeline;