import React from 'react';
import logo from './logo.svg'; 
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { history } from "./shared";
import './App.css';
import Weblayout from './website'
import Home from 'website/Home';
import Login from 'Auth/Login';
import AboutUs from 'website/AboutUs';
import ForgetPassword from 'Auth/ForgetPassword';
import ResetPassword from 'Auth/ResetPassword';
import Register from 'Auth/Register';
import DesertSafariDubaiTours from 'website/DesertSafariDubaiTours';
import TourDetail from 'website/TourDetail';
import Gallery from 'website/Gallery';
import Cmslayout from 'components/cmslayout/Cmslayout';
import ContactUs from 'website/ContactUs';  
import TdtCheckOut from 'website/TdtCheckOut';
import PasswordForget from 'Auth/PasswordForget';
import Dashboard from 'cmspages/Dashboard';
import AboutUsPage from 'cmspages/AboutUsPage';

function App() {



  return ( 
    <BrowserRouter>
      <Routes>  
          <Route path="/" element={<Weblayout className='appweblaout' children={<Home />}  />} />
          <Route path="/desert-safari-dubai-tours" element={<Weblayout className='appweblaout' children={<DesertSafariDubaiTours />}  />} />
          <Route path="/sight-seeing-dubai-tours" element={<Weblayout className='appweblaout' children={<DesertSafariDubaiTours />}  />} />
          <Route path="/private-tours-dubai-tours" element={<Weblayout className='appweblaout' children={<DesertSafariDubaiTours />}  />} />
          <Route path="/executive-dubai-tours" element={<Weblayout className='appweblaout' children={<DesertSafariDubaiTours />}  />} />
          <Route path="/dhow-cruise-dubai-tours" element={<Weblayout className='appweblaout' children={<DesertSafariDubaiTours />}  />} />
          <Route path="/combo-deals-dubai-tours" element={<Weblayout className='appweblaout' children={<DesertSafariDubaiTours />}  />} />
          <Route path="/tour-detail" element={<Weblayout className='appweblaout' children={<TourDetail />}  />} />
          <Route path="/about-us" element={<Weblayout className='appweblaout' children={<AboutUs />}  />} />
          <Route path="/gallery" element={<Weblayout className='appweblaout' children={<Gallery />}  />} />
          <Route path="/contact-us" element={<Weblayout className='appweblaout' children={<ContactUs />}  />} />
          <Route path="/check-out" element={<Weblayout className='appweblaout' children={<TdtCheckOut />}  />} />
          <Route path="/admin-panel" element={<Cmslayout className='appweblaout' children={<Dashboard />}  />} />
          <Route path="/about-us-page" element={<Cmslayout className='appweblaout' children={<AboutUsPage/>}  />} />
          <Route path="/about-g" element={<Cmslayout className='appweblaout' children={<DesertSafariDubaiTours />}  />} />
          <Route path="/deluxe-login" element={<Login />} /> 
          <Route path="/forget-password" element={<PasswordForget />} /> 
          <Route path="/reset-password" element={<ResetPassword />} /> 
          <Route path="/register" element={<Register />} /> 
      </Routes>
  </BrowserRouter>
  );
}

export default App;
