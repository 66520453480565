import { Avatar, Badge } from 'antd';
import React from 'react'; 
import { Col, Container, Row } from 'react-bootstrap';
import TdtIcon from './TdtIcon';
import { FaCheck } from "react-icons/fa6";
import TdtImage from './TdtImage';
import TdtListStyle from './TdtListStyle';

interface Reasonstworkprops{
    icon?:any 
    count?:number
}

const TdtWhoWeAre: React.FC<Reasonstworkprops> = (props) => {
  
  const listdata =[
    <TdtIcon icon={<FaCheck />} label={<React.Fragment><span> </span> Our Support team is highly knowledgeable and never transfers you to another department.</React.Fragment>}/>,
    <TdtIcon icon={<FaCheck />} label={<React.Fragment><span> </span> Our Support team is highly knowledgeable and never transfers you to another department.</React.Fragment>}/>,
    <TdtIcon icon={<FaCheck />} label={<React.Fragment><span> </span> Our Support team is highly knowledgeable and never transfers you to another department.</React.Fragment>}/>,
    <TdtIcon icon={<FaCheck />} label={<React.Fragment><span> </span> Our Support team is highly knowledgeable and never transfers you to another department.</React.Fragment>}/>,
    <TdtIcon icon={<FaCheck />} label={<React.Fragment><span> </span> Our Support team is highly knowledgeable and never transfers you to another department.</React.Fragment>}/>,
    <TdtIcon icon={<FaCheck />} label={<React.Fragment><span> </span> Our Support team is highly knowledgeable and never transfers you to another department.</React.Fragment>}/>,
  ]

  return (
    <Container fluid className='TdtWhoWeAre'>
        <Container>
            <Row>
                <Col sm={12} md={6} className='whovr-col1'>
                    <h3>Who We Are, Book Tour With Us</h3>
                     <TdtListStyle listdata={listdata} bordered={false} className='stylelist' />
                </Col>
                <Col className='whovr-col2'>
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/whoweare.png'}/>   
                </Col>
            </Row>
        </Container>
    </Container>
  );
}

export default TdtWhoWeAre;