import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from "react-helmet-async";
import { motion, useScroll, useSpring } from "framer-motion";
import OurServices from './homesections/OurServices';
import BannerSecTwo from './homesections/BannerSecTwo';
import TdtImage from 'components/websitecomponents/TdtImage';
import { Form } from 'antd';
import { WebApi } from 'shared/WebApi';
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaEnvelope, FaPhone } from 'react-icons/fa6';
import TdtTextarea from 'components/websitecomponents/TdtTextarea';
import TdtButton from 'components/websitecomponents/TdtButton';
import TdtCauroselPackage from 'components/websitecomponents/TdtCauroselPackage';



interface ContactUsprops {

}
export const ContactUs: React.FunctionComponent<ContactUsprops> = (props) => {
   
    const [form] = Form.useForm()
    const initialValues = { location_type : "Head Office"}
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const onFinish = (values: any) => {
        
        // values.user_id = localStorage.getItem("user_id");
        // values.company_account_type = 1;
        WebApi('post','/company/add', values)
        .then(response => {
            const responseData:any = response;
            if (responseData.status == 200 && responseData.data.message == "success") { 
                form.resetFields();
            }else if(responseData.status == 200 && responseData.data.message == "error"){
                console.error('Error:'); 
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    const relatedpackages = [
        {
            img: process.env.PUBLIC_URL  + 'assets/tra7.png',
            title: "Places to visit in Dubai 4", 
        },
        {
            img: process.env.PUBLIC_URL  + 'assets/tra8.png',
            title: "Places to visit in Dubai 5", 
        },
        {
            img: process.env.PUBLIC_URL  + 'assets/tra9.png',
            title: "Places to visit in Dubai 6", 
        },
        {
            img: process.env.PUBLIC_URL  + 'assets/tra10.png',
            title: "Places to visit in Dubai 7", 
        },
        {
            img: process.env.PUBLIC_URL  + 'assets/tra11.png',
            title: "Places to visit in Dubai 8", 
        },
        {
            img: process.env.PUBLIC_URL  + 'assets/tra12.png',
            title: "Places to visit in Dubai 9", 
        },
    ]; 

    return (
        <React.Fragment> 
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Contact Us are available for you" />
                <link rel="canonical" href="/contact-us" />
                <meta property="og:title" content="A very important title" />
            </Helmet>   

            <Container fluid className='bannerBread py-5 position-relative' style={{backgroundImage:'url(http://localhost:3000/assets/contactus.jpg)'}}> 
                <Container className='bannerbredcontent'>
                    <Row>
                        <Col sm={12} className='py-5'>
                            <h4 className='text-light'>Contact Us</h4>
                        </Col>
                    </Row>
                </Container>
                <div className='ovelaybred'></div>
            </Container> 

            <Container fluid className=''> 
                <Container className='py-5'>
                    <Row> 
                        <Col sm={12}  className='text-center py-5  px-sm-1 px-md-5 '> 
                            <h1 className='pb-3'>We are here, Chat with us</h1>
                            <p className='px-sm-1 px-md-5'>The  Deluxe Travel continues their tour in 2024, touching down in Subcontinent,  Europe and  America.</p>
                        </Col>
                        <Col sm={12} md={6} className=''> 
                            <div className='contactus-col1 box-shadow-tdt p-4'>
                                <h5 className='ps-3 pb-2'>Reach us</h5>
                                <p className='ps-3 pb-2'>The  Deluxe Travel continues their tour in 2024, touching down in Subcontinent,  Europe and  America.</p>
                                <Form
                                    name="AuditeeAddForm"
                                    form={form}
                                    initialValues={initialValues}
                                    // initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout="vertical"
                                // validateMessages={validateMessages}
                                > 
                                    <Row>
                                        <Col sm={12} md={6} className=' '> 
                                            <div className='contcdiv1'>
                                                <TdtInput 
                                                    className="inputclas class-fname"
                                                    name="fname"
                                                    type="text"
                                                    required={true}
                                                    requiredMessage="Please input your first name!"
                                                    label='First Name'
                                                    placeholder='Enter First Name'
                                                    allowClear={true}
                                                    // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                               /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} className=' '> 
                                            <div className='contcdiv1'>
                                                <TdtInput 
                                                    className="inputclas class-lname"
                                                    name="lname"
                                                    type="text"
                                                    required={true}
                                                    requiredMessage="Please input your last name!"
                                                    label='Last Name'
                                                    placeholder='Enter Last Name'
                                                    allowClear={true}
                                                    // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                                /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} className='  '> 
                                            <div className='contcdiv2'>
                                                <TdtInput 
                                                    className="inputclas class-email"
                                                    name="number"
                                                    type="text"
                                                    required={true}
                                                    requiredMessage="Please input your number!"
                                                    label='Phone Number'
                                                    placeholder='Enter number'
                                                    allowClear={true}
                                                    prefix={<TdtIcon icon={<FaPhone/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                                /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} className=' '> 
                                            <div className='contcdiv1'>
                                                <TdtInput
                                                    className="inputclas class-subject"
                                                    name="subject"
                                                    type="text"
                                                    required={true}
                                                    requiredMessage="Please input your Email!"
                                                    label='From Email'
                                                    placeholder='Enter Email'
                                                    allowClear={true}
                                                    prefix={<TdtIcon icon={<FaEnvelope/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                                /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} className='  '> 
                                            <div className='contcdiv1'>
                                                <TdtInput
                                                    className="inputclas class-subject"
                                                    name="subject"
                                                    type="text"
                                                    required={false}
                                                    requiredMessage="Please input your subject!"
                                                    label='Subject'
                                                    placeholder='Enter Subject'
                                                    allowClear={true}
                                                    // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                                /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} className=' '> 
                                            <div className='contcdiv1'>
                                                <TdtTextarea
                                                    className="inputclas class-message"
                                                    name="message"
                                                    type="text"
                                                    required={false}
                                                    requiredMessage="Please input your Message!"
                                                    label='Message'
                                                    placeholder='Enter Message'
                                                    allowClear={true}
                                                    // prefix={<TdtIcon icon={<FaPhone/>}/>}
                                                    size="large"
                                                    ruleType='text'
                                                /> 
                                            </div>
                                        </Col>
                                        <Col sm={12} className=' '> 
                                            <TdtButton  htmlType={"submit"} label='Submit' classNamebtn={'w-100'} />
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                        <Col sm={12} md={6} className=''> 
                            <div className='contactus-col2  box-shadow-tdt p-4'>
                                <h5 className=' pb-3'>We are here</h5>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462563.0327172183!2d54.89782865650008!3d25.07565839539666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1722067423252!5m2!1sen!2s" width="100%" height="470"   loading="lazy"  ></iframe>
                            </div>
                        </Col>
                        <Col sm={12}  className=''> 
                            <div className='pt-5'>
                                <h5 className=''>Our Best Selling Tours</h5> 
                                <TdtCauroselPackage adventureindubai={relatedpackages} dots={false} infinite={true} speed={500} slidesToShow={5} slidesToScroll={1} className=' mt-m15'/>  
                            </div>
                        </Col>
                    </Row>   
                </Container > 
            </Container> 

        </React.Fragment> 


    )
}

export default ContactUs;




