import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import TdtImage from "./TdtImage";
import TdtButton from "./TdtButton";
import TdtIcon from "./TdtIcon"; 
import { AiOutlineClockCircle } from "react-icons/ai";
import { LiaCommentsDollarSolid } from "react-icons/lia";
import { Badge, Card } from "antd"; 
import { Space, Typography } from 'antd';
 

interface Tdttourspackageprops {

}

const TdtToursPackage: React.FC<Tdttourspackageprops> = (props) => {
  return (
    <Container  className='homecatpkgs'>
      
      <Row className="TdtToursPackage-row py-5">
        
        <Col sm={12} className="d-flex justify-content-between pb-3">
          <h5 className="setheadingrow hightlight"> Desert Safari</h5>
          <h5 className="setheadingrow">Adventure In Dubai</h5>
          <TdtButton label="View All" href="#" />
        </Col>

        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="23% OFF">
                <a href="">
                    <Card title=" Private Abu Dhabi City Tour " size="small">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                        <div className="pmmentduratoin d-flex justify-content-between">
                            <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                            <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                        </div> 
                    </Card> 
                </a>
            </Badge.Ribbon> 
        </Col>
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="15% OFF">
                <a href="">
                    <Card title=" Private Abu Dhabi City Tour " size="small">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                        <div className="pmmentduratoin d-flex justify-content-between">
                            <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                            <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                        </div> 
                    </Card> 
                </a>
            </Badge.Ribbon> 
        </Col>
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="65% OFF">
                <a href="">
                    <Card title=" Private Abu Dhabi City Tour " size="small">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                        <div className="pmmentduratoin d-flex justify-content-between">
                            <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                            <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                        </div> 
                    </Card> 
                </a>
            </Badge.Ribbon> 
        </Col>
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="33% OFF">
                <a href="">
                    <Card title=" Private Abu Dhabi City Tour " size="small">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                        <div className="pmmentduratoin d-flex justify-content-between">
                            <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                            <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                        </div> 
                    </Card> 
                </a>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="28% OFF">
                <a href="">
                    <Card title=" Private Abu Dhabi City Tour " size="small">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                        <div className="pmmentduratoin d-flex justify-content-between">
                            <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                            <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                        </div> 
                    </Card> 
                </a>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="22% OFF">
                <a href="">
                    <Card title=" Private Abu Dhabi City Tour " size="small">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                        <div className="pmmentduratoin d-flex justify-content-between">
                            <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                            <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                        </div> 
                    </Card> 
                </a>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <a href="">
                    <Card title=" Private Abu Dhabi City Tour " size="small">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                        <div className="pmmentduratoin d-flex justify-content-between">
                            <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                            <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                        </div> 
                    </Card> 
                </a>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <a href="">
                    <Card title=" Private Abu Dhabi City Tour " size="small">
                        <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                        <div className="pmmentduratoin d-flex justify-content-between">
                            <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                            <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                        </div> 
                    </Card> 
                </a>
            </Badge.Ribbon> 
        </Col>  
      </Row>
      
      <Row className="TdtToursPackage-row py-5">
        
        <Col sm={12} className="d-flex justify-content-between pb-3">
          <h5 className="setheadingrow hightlight">Sight Seeing</h5>
          <h5 className="setheadingrow">Adventure In Dubai</h5>
          <TdtButton label="View All" href="#" />
        </Col>

        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col>
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col>
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col>
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col>  
      </Row>
      
      <Row className="TdtToursPackage-row py-5">
        
        <Col sm={12} className="d-flex justify-content-between pb-3">
          <h5 className="setheadingrow hightlight">Private Tours</h5>
          <h5 className="setheadingrow">Adventure In Dubai</h5>
          <TdtButton label="View All" href="#" />
        </Col>

        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col>
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col>
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col>
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col> 
        <Col className="p-0" sm={12} md={3}> 
            <Badge.Ribbon text="25% OFF">
                <Card title=" Private Abu Dhabi City Tour " size="small">
                    <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/tra5.jpg'}/> 
                    <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration"><div className="startdurationtxt">Duration</div><TdtIcon icon={<AiOutlineClockCircle/>} label={'9 Hours'}/></div> 
                        <div className="trvlpayment"><div className="startpamntfrtxt">Start From</div><TdtIcon icon={<LiaCommentsDollarSolid />} label={<div className="d-flex gap-2"><del>988</del><span className="touramount">900</span></div>}/></div>
                    </div>
        
                </Card>
            </Badge.Ribbon> 
        </Col>  
      </Row>
    
    </Container>
  );
}

export default TdtToursPackage;
