import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import TdtImage from "./TdtImage";
import TdtButton from "./TdtButton";
import TdtIcon from "./TdtIcon";
import { HiOutlineChatAlt } from "react-icons/hi";

interface adventureindubaiprops {
  adventureindubai?: any
  dots?:boolean
  infinite?:boolean
  speed?:number
  slidesToShow?:number
  slidesToScroll?:number
  className?:string

}
  
const TdtCauroselPackage: React.FC<adventureindubaiprops> = (props) => {
  const settings = {
    dots: props.dots,
    infinite: props.infinite,
    speed: props.speed,
    slidesToShow: props.slidesToShow,
    slidesToScroll: props.slidesToScroll
  };
  return (
    <Container  className={`TdtCauroselPackage ${props.className}`}>
      
      <Row className="slider-container"> 

        <Col className="p-0">
          <Slider {...settings}>   
            {props.adventureindubai.map((item:any, i:any) => (
                <div className="card-setsider px-2">
                <TdtImage preview={false} src={item.img}/>
                <h6 className="settitlepkg">{item.title}</h6>
              </div> 
            ))}  
          </Slider>
        </Col>

      </Row>
    
    </Container>
  );
}

export default TdtCauroselPackage;
